import Vue from 'vue';
import moment from 'moment';

export const mixins = {
    methods: {
        validRoles(user_role, roles) {
            return roles.indexOf(user_role) !== -1;
        },
        sumHoursOdoo(date) {
            const date1 = moment(date).add(6, 'hours').format('YYYY-MM-DD HH:mm:ss')

            return date1
        },
        sumHoursOdooV(date) {
            const date1 = moment(date).add(6, 'hours').format('YYYY-MM-DD HH:mm:ss')

            return date1
        },
        resHoursOdoo(date) {
            const date1 = moment(date).subtract(6, 'hours').format('YYYY-MM-DD HH:mm')

            return date1
        },
        dateOdooSend(date) {
            const date1 = moment(date).format('YYYY-MM-DD HH:mm')

            return date1
        },
        resHoursOdooFormat(date) {
            const date1 = moment(date).subtract(6, 'hours').format('DD-MM-YYYY HH:mm')

            return date1
        },
        resHoursOdooFormatSix(date) {
            const date1 = moment(date).subtract(6, 'hours').format('DD-MM-YYYY HH:mm')

            return date1
        },
        resHoursOdooFormatNoHour(date) {
            const date1 = moment(date).subtract(6, 'hours').format('DD-MM-YYYY')

            return date1
        },
        formFiltration(age, weight, sex, crea) {
            let creatinina;
            if (!crea || crea === "")
            {
                creatinina = 1
            }else{
                creatinina = crea
            }
            const total_div = creatinina * 72
            if (sex === 'F') {
                const calculate = ((140 - parseInt(age)) * (parseFloat(weight)) / total_div ) * (0.85)
                if (calculate === Infinity){
                    return 0
                }
                if (calculate < 0) {
                    return 0
                } else if (calculate > 0){
                    return calculate.toFixed(2)
                }else{
                    return 0
                }
            } else if (sex === 'M'){
                const calculate = ((140 - parseInt(age)) * (parseFloat(weight)) / total_div )
                if (calculate < 0) {
                    return 0
                } else {
                    return calculate.toFixed(2)
                }
            }else{
                return 0
            }
        },
        formCarbolatino(auc, filtration) {
            // filtracvion + 25 si esto es mayor a 125 se deja

            let to;
            const total_filt = parseFloat(filtration) + 25;

            if (total_filt > 125){
                to = 125
            }else if (total_filt < 125){

                to =  total_filt
            }else{
                return 0
            }
            return to * parseFloat(auc)
        },
        clearObj(obj) {
            Object.entries(obj).forEach(
                ([key, val]) =>
                    (val && typeof val === 'object' && this.clearObj(val)) ||
                    ((val === null || val === '') && delete obj[key])
            );
            return obj;
        },
        isNumber(evt) {
            evt = (evt) ? evt : window.event;
            const charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        typeFile(type) {
           if (type === 'NEW'){
               return 'Nuevo'
           }else{
               return 'Modificación'
           }
        },
        dateString(date) {
            const weekDayName = moment(date).format('dddd');
            return weekDayName
        },
        dateToday() {
            const weekDayName = moment().format('YYYY-MM-DD  HH:mm:ss');
            return weekDayName
        },
        dateTodayNotHours() {
            const weekDayName = moment().format('YYYY-MM-DD');
            return weekDayName
        },
        dateStringMx(date) {
            const weekDayName = moment(date).format('DD-MM-YYYY');
            return weekDayName
        },
        dateStringMxFormat(date) {
            const weekDayName = moment(date).format('DD/MM/YYYY');
            return weekDayName
        },
        dateStringMxNo(date) {
            const weekDayName = moment(date).format('DD MMY YYY');
            return weekDayName
        },
        checkAndAddNewPage(currentHeight, internal, doc) {
            const pageHeight = internal;

            const bottomMargin = 4;
            if (currentHeight >= pageHeight - bottomMargin) {
                doc.addPage();
                return true;
            }
            return false;
        },
        checkAndAddNewPageHistory(currentHeight, internal, doc) {
            const pageHeight = internal;

            const bottomMargin = 11;
            if (currentHeight >= pageHeight - bottomMargin) {
                doc.addPage();
                return true;
            }
            return false;
        },
        checkAndAddNewPagePt(currentHeight, internal, doc) {
            const pageHeight =internal;
            const bottomMargin = 150;
            if (currentHeight >= pageHeight - bottomMargin) {
                doc.addPage();
                return true;
            }
            return false;
        },
        checkAndAddNewPageInter(currentHeight, internal, doc) {
            const pageHeight =internal;
            const bottomMargin = 30;
            if (currentHeight >= pageHeight - bottomMargin) {
                doc.addPage();
                return 40;
            }
            return currentHeight;
        },

        diffDates(date1, date2) {
            const dateInitial = moment(date1);
            const dateEnd = moment(date2);

            return dateEnd.diff(dateInitial, 'days')
        },
        yearsCalculate(date) {
            const weekDayName =  moment().diff(date, 'years',false)
            return weekDayName
        },
        dateStringEs(date) {
            moment.locale('es')
            const month = moment(date).format('MMMM');
            const day = moment(date).format('dddd');
            const year = moment(date).format('LL');
            return year
        },
        capitalize(str) {
            const lower = str.toLowerCase();
            return str.charAt(0).toUpperCase() + lower.slice(1);
        },
        addBase64Prefix(base64Data, fileType) {
            let prefix = '';

            switch (fileType) {
                case 'pdf':
                    prefix = 'data:application/pdf;base64,';
                    break;
                case 'jpeg':
                case 'jpg':
                    prefix = 'data:image/jpeg;base64,';
                    break;
                case 'png':
                    prefix = 'data:image/png;base64,';
                    break;
            }

            return prefix + base64Data;
        },
        hoursCalendar(date) {
            const start = moment(date);
            const remainder = 60 - (start.minute() % 60);
            return moment(start).add(remainder, "minutes").format("YYYY-MM-DD HH:mm");
        },
        hoursCalendarHour(date) {
            const date1 = moment(date).add(1, 'hours').format('YYYY-MM-DD HH:mm:ss')
            const start = moment(date1);
            const remainder = 60 - (start.minute() % 60);
            return moment(start).add(remainder, "minutes").format("YYYY-MM-DD HH:mm");
        },
        hoursCalendarHourOdoo(date) {
            const date1 = moment(date).add(1, 'hours').format('YYYY-MM-DD HH:mm:ss')
            const start = moment(date1);
            const remainder = 60 - (start.minute() % 60);
            return moment(start).add(remainder, "minutes").format("YYYY-MM-DD HH:mm");
        },
        smokeForm(years, smokeQty) {
            const result = (parseFloat(years) * parseFloat(smokeQty)) / 20;
            return result;
        },
        changeTextSpanish(text) {
            if (text === 'new'){
                return 'Nuevas'
            }
            if (text === 'approved'){
                return  'Aprobadas'
            }
            if (text === 'rejected'){
                return  'Rechazadas'
            }
            if (text === 'pending'){
                return  'Pendientes'
            }
        },
        changeTextSpanishSingle(text) {
            if (text === 'new'){
                return 'Nueva'
            }
            if (text === 'approved'){
                return  'Aprobada'
            }
            if (text === 'rejected'){
                return  'Rechazada'
            }
            if (text === 'pending'){
                return  'Pendiente'
            }
        },
        changeTextSpanishSingleInventory(text) {
            if (text === 'draft'){
                return 'Borrador'
            }
            if (text === 'assigned'){
                return  'Listo'
            }
            if (text === 'done'){
                return  'Hecho'
            }
            if (text === 'pending'){
                return  'Pendiente'
            }
            if (text === 'confirmed'){
                return  'En espera de disponibilidad'
            }
        }
    }
}
Vue.mixin(mixins)
