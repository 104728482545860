import Vue from 'vue'
import router from "../../../router";
import store from '../../../store';

import {requestFetch} from '../../config'

export default {
    namespaced: true,
    state: {
        user: null,
        img_shop:null,
        token: '',
        token_topic: '',
        errors: [],
        notification: false,
        password_seller: '',
        modal_initial: false

    },
    mutations: {
        setUser(state, user) {
            state.user = user
        },
        setImg(state, img_shop) {
            state.img_shop = img_shop
        },
        setToken(state, token) {
            state.token = token;
        },
        setErrors(state, error) {
            if (error === 'clear') {
                state.errors = [];
            } else {
                state.errors.push(error);
            }
        },

        setPasswordSeller(state, password_seller) {
            state.password_seller = password_seller
        },
        setModalInitial(state, modal_initial) {
            state.modal_initial = modal_initial
        },
        setNotification(state, notification) {
            state.notification = notification
        },
        setTopicToken(state, token_topic) {
            state.token_topic = token_topic
        },
    },
    actions: {
        //SET ERRORS
        setErrors({commit}, error) {
            commit('setErrors', error)
        },
        setModalInitial({commit}, modal_initial) {
            commit('setModalInitial', modal_initial)
        },
        setPassword({commit}, password_seller) {
            commit('setPasswordSeller', password_seller)
        },
        setTopicToken({commit}, setTopicToken) {
            commit('setTopicToken', setTopicToken)
        },

        // LOGIN
        async signin({commit}, user) {
            const response = await requestFetch(`/api/auth/login`, 'POST', user);
            if (response.error) return response;
            commit('setUser', response.user);
            commit('setToken', 'Bearer ' + response.access_token);
            return response;

        },
        async createTopic({commit}, user) {
            const response = await requestFetch(`/api/auth/create_topic`, 'POST', user);
            commit('setTopicToken', user.token)

        },
        // USER ME
        async userme({commit}) {
            let response;
            if (!store.getters["auth/getToken"]) {
                commit('setUser', null);
            } else {
                response =  await requestFetch(`/api/user/me`, 'GET');
                if (response.error) {
                    commit('setUser', null);
                    commit('setToken', '');
                }
                commit('setUser', response.user);
            }

            return response;

        },

        //LOG OUT
        async logout({commit}) {
            const verify = await store.getters['auth/getUser'];

            commit('setToken', '');
            commit('setUser', undefined);
            commit('setImg', undefined);
            await router.replace('/login');
        },


    },
    getters: {
        getUser(state) {
            return state.user;
        },
        getErrors(state) {
            return state.errors;
        },
        getToken(state) {
            return state.token;
        },
        getTokenTopic(state) {
            return state.token_topic;
        },


    }

}
