import Vue from 'vue'
import router from "../../../router";
import store from '../../../store';
import {mixins} from '../../../mixins';
import {requestFetch, requestFetchFile} from '../../config'

export default {
    namespaced: true,
    state: {

    },
    mutations: {


    },
    actions: {
        // LOGIN
        async findAppointmentUser({commit}) {
            const response = await requestFetch(`/api/appointment/user`, 'GET');
            return response.appointments;
        },
        async findAppointmentUserOne({commit},data) {
            const response = await requestFetch(`/api/appointment/one/${data}`, 'GET');
            return response.appointment;
        },
        async findAppointmentDoctor({commit}) {
            const response = await requestFetch(`/api/doctor/all`, 'GET');
            return response.doctors;
        },
        async findAppointmentDoctorPlace({commit}) {
            const response = await requestFetch(`/api/doctor/all/no_place`, 'GET');
            return response.doctors;
        },
        async findAppointmentDoctorPlaceAppoi({commit}) {
            const response = await requestFetch(`/api/doctor/all/place`, 'GET');
            return response.doctors;
        },
        async findAppointmentPriceList({commit}) {
            const response = await requestFetch(`/api/appointment/price_list`, 'GET');
            return response.pricelists;
        },
        async findAppointmentGroups({commit}) {
            const response = await requestFetch(`/api/appointment/groups`, 'GET');
            return response.groups;
        },
        async findAppointmentSource({commit}) {
            const response = await requestFetch(`/api/appointment/source`, 'GET');
            return response.sources;
        },
        async appointmentAvailable({commit},data) {
            const response = await requestFetch(`/api/appointment/verify_date`, 'POST', data);
            if (response.available){
                return true
            }else{
                return false
            }
        },

        async appointmentAvailableAndId({commit},data) {
            const response = await requestFetch(`/api/appointment/verify_date_id`, 'POST', data);
            if (response.available){
                return true
            }else{
                return false
            }
        },

        async appointmentAvailablePaxmanAndId({commit},data) {
            const response = await requestFetch(`/api/appointment/verify_date_paxman_id`, 'POST', data);
            if (response.available){
                return true
            }else{
                return false
            }
        },

        async appointmentAvailablePaxman({commit},data) {
            const response = await requestFetch(`/api/appointment/verify_date_paxman`, 'POST', data);
            if (response.available){
                return true
            }else{
                return false
            }
        },

        async getListAppointment({commit}) {
            const response = await requestFetch(`/api/appointment/list_reason`, 'GET');
            return response.list
        },
        async appointmentAvailableNot({commit},data) {
            const response = await requestFetch(`/api/appointment/verify_date/notId`, 'POST', data);
            if (response.available){
                return true
            }else{
                return false
            }
        },
        async downloadFileAppointment({commit},data) {
            const response = await requestFetchFile(`/api/appointment/download_file/${data.id}`, 'GET');
            var url = window.URL.createObjectURL(response);
            var a = document.createElement('a');
            a.href = url;
            a.download = data.name + '-' + data.partner + '.'+data.type;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
        },
        async downloadFileAppointmentAll({commit},data) {
            const response = await requestFetchFile(`/api/appointment/download_file_all/${data.id}/${data.file_number}`, 'GET');
            var url = window.URL.createObjectURL(response);
            if (!data.download){
                window.open(url)
                return
            }

            var a = document.createElement('a');
            a.href = url;

            if (data.name_file){
                a.download = data.name + '-' + data.name_file;
            }else{
                a.download = data.name + '-' + data.partner+data.type;
            }
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
        },
        async findSlotVerify({commit},data) {
            const response = await requestFetch(`/api/appointment/slot/${data}`, 'GET');
            if (response.slot){
                return response.slot.id
            }else{
                return ''
            }
        },

        async deleteFile({commit},data) {
            const response = await requestFetch(`/api/appointment/delete_file/${data}`, 'PUT');
            if (response.msg){
                return true
            }else{
                return false
            }
        },
        async deleteFileAll({commit},data) {
            const response = await requestFetch(`/api/appointment/delete_file_all/${data.id}/${data.file_number}`, 'PUT');
            if (response.msg){
                return true
            }else{
                return false
            }
        },
        async validateAppointment({commit},data) {
            const response = await requestFetch(`/api/appointment/validate/${data}`, 'PUT');
            if (response.msg){
                return true
            }else{
                return false
            }
        },
        async deleteAppointmentUp({commit},data) {
            const response = await requestFetch(`/api/treatment/delete_appointment/${data}`, 'PUT');
            if (response.msg){
                return true
            }else{
                return false
            }
        },
        async updateStateAppointment({commit}, {id,state}) {
            const response = await requestFetch(`/api/treatment/changeState/${id}/${state}`, 'PUT');
            if (response.msg){
                return true
            }else{
                return false
            }
        },
        async findApointeeSearch({commit},data) {
            const response = await requestFetch(`/api/doctor/group/${data}`, 'GET');
            return response.doctors
        },
        async findApointeeDoctorGroup({commit},data) {
            const response = await requestFetch(`/api/doctor/partner/${data}`, 'GET');
            return response.doctor
        },
        async findAppointmentVerify({commit},data) {
            const response = await requestFetch(`/api/appointment/verify_all`, 'POST', data);
            return response.appointments
        },
        async getTypeAppointment({commit}) {
            const response = await requestFetch(`/api/appointment/type_appointment`, 'get');
            return response.types
        },
        async createAppointmentSend({commit},data) {
            const newinitial = mixins.methods.sumHoursOdooV(data.app_dt_start)
            const newend = mixins.methods.sumHoursOdooV(data.app_dt_stop)
            data.app_dt_start = newinitial
            data.app_dt_stop = newend
            const response = await requestFetch(`/api/appointment/create`, 'POST', data);
            if (response.msg){
                return true
            }else{
                return false
            }
        },
        async createAppointmentSendAll({commit},data) {
            const response = await requestFetch(`/api/appointment/this.sumHoursOdooV(`, 'POST', data);
            if (response.msg){
                return true
            }else{
                return false
            }
        },
        async createSaleOrder({commit},data) {
            const response = await requestFetch(`/api/treatment/validate/${data.id}/${data.appointee_id}`, 'PUT');
            if (response.msg){
                return true
            }else{
                return false
            }
        },
        async createConfirmOrder({commit},data) {
            const response = await requestFetch(`/api/treatment/confirm_order/${data.id}/${data.appointee_id}`, 'PUT', data);
            if (response.msg){
                const resp = {
                    status: true,
                    msg: 'Orden confirmada',
                }
                return resp
            }else{
                const resp = {
                    status: false,
                    error: response.error,
                }
                return resp
            }
        },
        async updateStockOrder({commit}, {id,treatment_id}) {
            const response = await requestFetch(`/api/treatment/stock/${id}/${treatment_id}`, 'PUT');
            if (response.msg){
                return true
            }else{
                return false
            }
        },
        async createCheckAppointment({commit},data) {
            const response = await requestFetch(`/api/appointment/check_appointment`, 'POST', {appointment_id:data});
            return response.appointment;
        },
    },
    getters: {
    }

}
