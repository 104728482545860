import Vue from 'vue'
import router from "../../../router";
import store from '../../../store';

import {requestFetch} from '../../config'

export default {
    namespaced: true,
    state: {

    },
    mutations: {


    },
    actions: {
        // LOGIN
        async findHospitalsLab({commit}, data) {
            const response = await requestFetch(`/api/v2/hospital?name=${data}`, 'GET');
            return response;
        },

        async findHospitalsAll({commit}, data) {
            const response = await requestFetch(`/api/v2/hospital`, 'GET');
            return response.hospitals;
        },
        async createHospitalsLab({commit}, data) {
            const response = await requestFetch(`/api/v2/hospital`, 'POST', data);
            if (response.msg){
                return response.msg
            }else{
                return {
                    error:"No se pudo crear el producto"
                }
            }
        },


    },
    getters: {
    }

}
