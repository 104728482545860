import Vue from 'vue'
import router from "../../../router";
import store from '../../../store';

import {requestFetch, requestFetchFile} from '../../config'

export default {
    namespaced: true,
    state: {

    },
    mutations: {


    },
    actions: {

        async findPatientAllInterConsultationsDesc({commit}, id) {
            const response = await requestFetch(`/api/v2/interConsultations/desc/${id}`, 'GET');
            return response.consultations;

        },
        async findPatientOneInterConsultationsDesc({commit}, id) {
            const response = await requestFetch(`/api/v2/interConsultations/one/${id}`, 'GET');
            return response.consultation;

        },
        async createInterConsultation({commit}, data) {
            const response = await requestFetch(`/api/v2/interConsultations`, 'POST', data);
            if (response.msg){
                return true
            }else{
                return response.error
            }
        },
        async updateInterConsultation({commit}, data) {
            const response = await requestFetch(`/api/v2/interConsultations`, 'PUT', data);
            if (response.msg){
                return true
            }else{
                return response.error
            }
        },
        async deleteInterConsultations({commit}, id) {
            const response = await requestFetch(`/api/v2/interConsultations/${id}`, 'DELETE');
            if (response.msg){
                return true
            }else{
                return response.error
            }
        },

        async getMedicalsInter({commit}) {
            const response = await requestFetch(`/api/v2/interConsultations/medicals`, 'GET');
            return response.medicals;
        },

        async createExtraMedical({commit}, data) {
            const response = await requestFetch(`/api/v2/interConsultations/medical`, 'POST', data);
            if (response.msg){
                return {
                    status:true,
                    msg:response.msg
                }
            }else{
                return {
                    error:response.error,
                    status:false
                }
            }
        },

    },
    getters: {
    }

}
