import Vue from 'vue'
import router from "../../../router";
import store from '../../../store';

import {requestFetch} from '../../config'

export default {
    namespaced: true,
    state: {

    },
    mutations: {


    },
    actions: {
        // LOGIN
        async findAllPharmacy({commit},data) {
            const response = await requestFetch(`/api/v2/pharmacy/all?${data}`, 'GET');
            return response;
        },
        async getConsecutive({commit}, {id}) {
            const response = await requestFetch(`/api/v2/pharmacy/consecutive/${id}`, 'GET');
            return response;
        },
        async getConsecutiveList({commit}, {id, position,data}) {
            const response = await requestFetch(`/api/v2/pharmacy/consecutiveList/${id}/position/${position}`, 'PUT', data);
            return response;
        },
        async createNewLabel({commit},data) {
            const response = await requestFetch(`/api/v2/pharmacy`, 'POST',data);
            return response;
        },
        async updateLabel({commit}, {id,data}) {
            const response = await requestFetch(`/api/v2/pharmacy/edit/${id}`, 'PUT',data);
            return response;
        },
        async updateStatus({commit}, {id,type}) {
            const response = await requestFetch(`/api/v2/pharmacy/status/${id}/${type}`, 'PUT');
            return response;
        },

        async generateQrNotLabel({commit}, data) {
            const response = await requestFetch(`/api/v2/pharmacy/createQr`, 'POST',data);
            return response;
        },
        async generateQrId({commit}, id) {
            const response = await requestFetch(`/api/v2/pharmacy/qr/${id}`, 'GET');
            return response;
        },
        async getOne({commit}, id) {
            const response = await requestFetch(`/api/v2/pharmacy/one/${id}`, 'GET');
            return response;
        },
    },
    getters: {
    }

}
