import Vue from 'vue'
import router from "../../../router";
import store from '../../../store';

import {requestFetch, requestFetchFile} from '../../config'

export default {
    namespaced: true,
    state: {

    },
    mutations: {


    },
    actions: {
        // LOGIN

        async findPatientAllFiles({commit}, {id}) {
            const response = await requestFetch(`/api/v2/insuranceFiles/patient/${id}`, 'GET');
            return response.files;

        },

        async createPatientFiles({commit}, data) {
            const response = await requestFetch(`/api/v2/insuranceFiles/patient/`, 'POST', data);
            if (response.msg){
                return true
            }else{
                return false
            }
        },


        async downloadFiPatientOne({commit},data) {
            const response = await requestFetch(`/api/v2/insuranceFiles/patient_download/${data.id}`, 'GET');
            var a = document.createElement('a');
            a.href = response.msg;
            a.download = response.msg
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
        },

        async updateFileHistory({commit}, {id,data}) {
            const response = await requestFetch(`/api/v2/insuranceFiles/patient/${id}`, 'PUT',data);
            if (response){
                return true
            }else{
                return false
            }
        },

        async findPatientOneFiles({commit}, {id,patient}) {
            const response = await requestFetch(`/api/v2/insuranceFiles/patient_one/${id}/${patient}`, 'GET');
            return response.files;

        },


    },
    getters: {
    }

}
